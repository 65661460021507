import React from "react"
import { graphql } from "gatsby"
import ArchiveLayout from "../components/archivelayout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import PaginationLinks from "../components/PaginationLinks"
import { Container, Row, Col } from "reactstrap"
import Post from "../components/Post"

import {site} from "../../theme"

const postList = (props) => {
  const posts = props.data.allMdx.edges
  const { currentPage, numberOfPages } = props.pageContext

  return (
    <ArchiveLayout pageTitle={`Page: ${currentPage}`}>
      <SEO
        title={`${site.title} Guides Page: ${currentPage}`}
      />
      <Container>
        <Row>
          <Col sm="8">
            {posts.map(({ node }) => (
              <Post
                title={node.frontmatter.title}
                sizes={node.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                date={node.frontmatter.data}
                body={node.frontmatter.description}
                slug={node.fields.slug}
                tags={node.frontmatter.tags}
              />
            ))}
            <PaginationLinks
              currentPage={currentPage}
              numberOfPages={numberOfPages}
            />
          </Col>

          <Col sm="4">
            <Sidebar />
          </Col>
        </Row>
      </Container>
    </ArchiveLayout>
  );
}

export const postListQuery = graphql`query postListQuery($skip: Int!, $limit: Int!) {
  allMdx(
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          tags
          description
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 500, height: 280, layout: CONSTRAINED)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`
export default postList
